<script setup lang="ts">
// [ node_modules ]
import * as $gtag from 'vue-gtag';
// [ stores ]
import { useLangStore } from '@/stores/lang-store';
// [lib]
import { GaFunc } from '@/lib/lib-front';
const router = useRouter();
const langStore = useLangStore();
const route = useRoute();

const clickNavigateHome = () => {
  $gtag.event(GaFunc.navigate, {
    from: route.path,
    to: `/${langStore.state.lang}/`,
    name: 'clickNavigateHome',
  });
  router.push(`/${langStore.state.lang}/`);
};
</script>
<template>
  <div>
    <div class="left">
      <div class="logo" @click="clickNavigateHome">
        <img src="/assets/img/inspire-llc-mono-logo-white-90.svg" alt="" width="25" height="170" />
      </div>
    </div>
    <slot />
    <div class="COPYRIGHT">COPYRIGHT ©2023 INSPIRE LLC.</div>
  </div>
</template>

<style lang="scss" scoped>
.left {
  position: fixed;
  height: 100vh;
  width: 20px;
  background-color: rgba(154, 154, 154, 0.233);
  mix-blend-mode: difference;
  display: flex;
  z-index: 1;

  @media screen and (min-width: #{ 0 }px) and (max-width: #{ 500 - 0.1}px) {
    width: 10px;
  }

  .logo {
    flex: 0 0 auto;
    margin: 10px 0 0 10px;
    width: 50px;
    height: 340px;
    cursor: pointer;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      inset: 0 0 0 0;
      pointer-events: none;
      z-index: -1;
      transition: all 300ms;
      transform: translateX(-100%);
    }

    &:hover::after {
      background-color: white;
      transform: translateX(0);
    }

    @media screen and (min-width: #{ 0 }px) and (max-width: #{ 500 - 0.1}px) {
      width: 25px;
      height: 170px;
      margin: 4px 0 0 4px;
    }
    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
      mix-blend-mode: difference;
    }
  }
}

.COPYRIGHT {
  position: fixed;
  inset: auto 0 0 auto;
  display: flex;
  justify-content: space-between;
  color: rgba(93, 93, 93, 0.664);
  font-size: 12px;
  line-height: 1em;
  padding: 0 2px 2px 0;

  @media screen and (min-width: #{ 0 }px) and (max-width: #{ 600 - 0.1}px) {
    flex-direction: column;
    align-items: center;
  }

  > div {
    font-size: 12px;
    padding: 2px;
  }
}
</style>
